export const tableFields = [
  {
    prop: 'name',
    label: 'ФИО',
    width: '300',
  },
  {
    prop: 'login',
    label: 'Роль',
  },
  {
    prop: 'phone',
    label: 'Телефон',
  },
  {
    prop: 'email',
    label: 'Email',
  },
]

export const profileTableData = [
  {
    id: 1,
    name: 'Иванов Иван Иванович',
    role: 'Начальник смены',
    phone: '+711111111111',
    email: 'email@email.com',
  },
  {
    id: 2,
    name: 'Иванов Иван Иванович',
    role: 'Начальник смены',
    phone: '+711111111111',
    email: 'email@email.com',
  },
  {
    id: 3,
    name: 'Иванов Иван Иванович',
    role: 'Начальник смены',
    phone: '+711111111111',
    email: 'email@email.com',
  },
  {
    id: 4,
    name: 'Иванов Иван Иванович',
    role: 'Начальник смены',
    phone: '+711111111111',
    email: 'email@email.com',
  },
]
