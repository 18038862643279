var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "profile-table" }, [
    _c(
      "div",
      { staticClass: "container" },
      [
        _c("ProfileTableHeader", {
          on: { emitFilterParams: _vm.handleFilteredData },
        }),
        _c("ProfileTableBody", {
          attrs: {
            "table-data": _vm.tableData,
            "table-fields": _vm.tableFields,
            "is-loading": _vm.isLoading,
          },
          on: {
            editRow: _vm.handleEdit,
            deleteRow: _vm.handleDelete,
            selectDropdownMenu: _vm.handleTableMenu,
            tableSortByKey: _vm.handleSort,
          },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function (slotProps) {
                return [
                  _c("ProfileTableCell", {
                    attrs: {
                      row: slotProps.data.row,
                      "prop-key": slotProps.data.prop,
                    },
                  }),
                ]
              },
            },
          ]),
        }),
        _c("TableFooter", {
          attrs: {
            "text-quantity": "Всего пользователей:",
            pagination: _vm.pagination,
          },
          on: { page: _vm.changePage, size: _vm.changeSize },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }